(function() {
    'use strict';

    angular
        .module('app')
        .factory('googleFactory', googleFactory);

    googleFactory.$inject = [];

    function googleFactory() {
        var auth2; // The Sign-In object.
        var googleUser; // The current user.
        var service = {
            auth2: auth2,
            googleUser: googleUser,
            loginCheck: loginCheck,
            init: init,
            signIn: signIn,
            getUser: getUser
        };

        return service;

        function init() {
            gapi.load('auth2', function() {
                auth2 = gapi.auth2.init({
                    client_id: '207701094496-4dmnrn1vk1kv4f100c4uap1ccgumo586.apps.googleusercontent.com',
                    scope: 'profile'
                });
            });
        }

        function signIn() {
            return auth2.signIn()
                .then(signInComplete, signInFailed);

            function signInComplete(googleUser) {
                var profile = googleUser.getBasicProfile();
                if (profile != undefined) {
                    return profile;
                }
                return null;
            }
            function signInFailed(error) {
                console.log('XHR Failed for signIn.' + error.data);
            }
        }

        function loginCheck() {
            return auth2.isSignedIn.get();
        }

        function getUser() {
            return auth2.currentUser.get().getBasicProfile();
        }

    }
})();
