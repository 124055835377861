(function () {
    "use strict";

    angular.module("app").controller("SocialController", SocialController);

    SocialController.$inject = [
        "$window",
        "facebookFactory",
        "googleFactory",
        "linkedinFactory",
        "socialFactory",
        "$scope",
    ];

    function SocialController(
        $window,
        facebookFactory,
        googleFactory,
        linkedinFactory,
        socialFactory,
        $scope
    ) {
        var vm = this;
        vm.login = login;
        vm.register = register;
        vm.fbLogin = fbLogin;
        vm.fbRegister = fbRegister;
        vm.googleLogin = googleLogin;
        vm.checkFields = checkFields;
        vm.linkedinLogin = linkedinLogin;
        vm.googleRegister = googleRegister;
        vm.linkedinRegister = linkedinRegister;
        vm.clearSocialRegForm = clearSocialRegForm;
        vm.toggleShowPassword = toggleShowPassword;
        vm.regEmailReadOnly = false;
        vm.onRegisterInit = onRegisterInit;
        vm.showRegPassword = false;
        vm.showConfPassword = false;
        vm.showPassword = false;

        activate();

        function activate() {
            facebookFactory.init();
            googleFactory.init();
        }

        function checkFields(registerForm, $event) {
            registerForm.email.$validate();
            registerForm.name.$validate();
            registerForm.password.$validate();
            registerForm.password_confirmation.$validate();
            registerForm.privacy.$validate();

            registerForm.email.$setDirty();
            registerForm.name.$setDirty();
            registerForm.password.$setDirty();
            registerForm.password_confirmation.$setDirty();
            registerForm.privacy.$setDirty();

            if (
                !registerForm.email.$valid ||
                !registerForm.name.$valid ||
                !registerForm.password.$valid ||
                !registerForm.password_confirmation.$valid ||
                !registerForm.privacy.$valid
            ) {
                $event.preventDefault();
            }

            onRegisterInit();
        }

        function toggleShowPassword(field) {
            var passwordFiled = document.querySelector(`#${field}`);
            var type =
                passwordFiled.getAttribute("type") === "password"
                    ? "text"
                    : "password";
            passwordFiled.setAttribute("type", type);
            field === "reg_password"
                ? (vm.showRegPassword = !vm.showRegPassword)
                : field === "password"
                ? (vm.showPassword = !vm.showPassword)
                : (vm.showConfPassword = !vm.showConfPassword);
        }

        function clearSocialRegForm() {
            vm.regEmailReadOnly = false;

            angular
                .element(
                    "#user-modal #register input[name='social[social_id]']"
                )
                .val("");
            angular
                .element("#user-modal #register input[name='social[type]']")
                .val("");
            angular
                .element("#user-modal #register input[name='social[name]']")
                .val("");
            angular
                .element("#user-modal #register input[name='social[avatar]']")
                .val("");

            $scope.email = "";
            $scope.name = "";
            $scope.password = "";
            $scope.passwordConfirm = "";

            $scope.registerForm.$setPristine();
            $scope.registerForm.$setUntouched();
        }

        function fbLogin() {
            FB.getLoginStatus(function (response) {
                if (response.status === "connected") {
                    vm.login(response.authResponse.userID, "facebook");
                } else {
                    facebookFactory.signIn().then(function (response) {
                        vm.login(response.authResponse.userID, "facebook");
                    });
                }
            });
        }

        function fbRegister() {
            FB.getLoginStatus(function (response) {
                if (response.status === "connected") {
                    facebookFactory.getUser().then(function (response) {
                        vm.register(
                            response.id,
                            response.name,
                            response.email,
                            response.picture.data.url,
                            "facebook"
                        );
                    });
                } else {
                    facebookFactory.signIn().then(function (response) {
                        facebookFactory.getUser().then(function (response) {
                            vm.register(
                                response.id,
                                response.name,
                                response.email,
                                response.picture.data.url,
                                "facebook"
                            );
                        });
                    });
                }
            });
        }

        function googleLogin() {
            if (googleFactory.loginCheck() == false) {
                googleFactory.signIn().then(function (profile) {
                    vm.login(profile.getId(), "google");
                });
            } else {
                vm.login(googleFactory.getUser().getId(), "google");
            }
        }

        function googleRegister() {
            if (googleFactory.loginCheck() == false) {
                googleFactory.signIn().then(function (profile) {
                    vm.register(
                        profile.getId(),
                        profile.getName(),
                        profile.getEmail(),
                        profile.getImageUrl(),
                        "google"
                    );
                });
            } else {
                var profile = googleFactory.getUser();
                vm.register(
                    profile.getId(),
                    profile.getName(),
                    profile.getEmail(),
                    profile.getImageUrl(),
                    "google"
                );
            }
        }

        function linkedinLogin() {
            if (linkedinFactory.loginCheck() == false) {
                linkedinFactory.signIn(callback);
            } else {
                callback();
            }

            function callback() {
                linkedinFactory.getUser().result(function (profile) {
                    if (profile.values.length > 0) {
                        vm.login(profile.values[0].id, "linkedin");
                    } else {
                    }
                });
            }
        }

        function linkedinRegister() {
            linkedinFactory.getUser().result(function (profile) {
                if (profile.values.length > 0) {
                    vm.register(
                        profile.values[0].id,
                        profile.values[0].formattedName,
                        profile.values[0].emailAddress,
                        profile.values[0].pictureUrl,
                        "linkedin"
                    );
                } else {
                    console.log("Not authorized");
                }
            });
        }

        function login(userID, provider) {
            socialFactory
                .login(userID, provider)
                .then(function (response) {
                    if (response.success === false) {
                        switch (provider) {
                            case "facebook":
                                vm.fbRegister();
                                break;
                            case "google":
                                vm.googleRegister();
                                break;
                            default:
                                vm.linkedinRegister();
                                break;
                        }
                    } else {
                        $window.location.reload();
                    }
                })
                .catch(function (data) {
                    console.log(data);
                });
        }

        function register(social_id, name, email, avatar, provider) {
            angular
                .element('#user-modal [name="social[social_id]"]')
                .val(social_id);
            angular.element('#user-modal [name="social[type]"]').val(provider);
            angular.element('#user-modal [name="social[name]"]').val(name);
            angular.element('#user-modal [name="social[avatar]"]').val(avatar);
            angular.element("#user-modal #name").val(name).trigger("change");

            if (email != undefined) {
                angular
                    .element('#user-modal [name="social[email]"]')
                    .val(email);
                angular
                    .element("#user-modal #email")
                    .val(email)
                    .trigger("change");
                vm.regEmailReadOnly = true;
            }

            angular
                .element('#user-modal .nav-tabs li a[href="#register"]')
                .trigger("click");
        }

        function onRegisterInit() {
            // google tag manager
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "registration" });
        }
    }
})();
