(function() {
    'use strict';

    angular
        .module('app')
        .factory('socialFactory', socialFactory);

    socialFactory.$inject = ['$http'];

    function socialFactory($http) {
        var service = {
            login: login
        };

        return service;

        function login(userId, provider) {
            return $http.post('/api/social/login/' + provider,
                { userId: userId }
            )
            .then(loginComplete)
            .catch(loginFailed);

            function loginComplete(response) {
                return response.data;
            }
            function loginFailed(error) {
                console.log('XHR Failed for loginFailed.' + error.data);
            }
        }
    }
})();
