(function () {
    'use strict';

    angular
        .module('app')
        .factory('facebookFactory', facebookFactory);

    facebookFactory.$inject = ['$q', '$window'];

    function facebookFactory($q, $window) {
        var service = {
            init: init,
            signIn: signIn,
            getUser: getUser,
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}

            js = d.createElement(s); js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        return service;

        function init() {
            $window.fbAsyncInit = function () {
                FB.init({
                    appId: '515976891937130',
                    status: true,
                    cookie: true,
                    xfbml: true,
                    version: 'v2.5',
                });
            };
        }

        function signIn() {
            var deferred = $q.defer();

            FB.login(function (response) {
                if (!response || response.error) {
                    deferred.reject('Error occured');
                } else if (response.status === 'connected') {
                    deferred.resolve(response);
                }
            }, { scope: 'public_profile,email' });

            return deferred.promise;
        }

        function getUser() {
            var deferred = $q.defer();
            FB.api('/me', {
                fields: 'name,email,picture.type(normal)',
            }, function (response) {
                if (!response || response.error) {
                    deferred.reject('Error occured');
                } else {
                    deferred.resolve(response);
                }
            });

            return deferred.promise;
        }
    }
})();
