(function() {
    'use strict';

    angular
        .module('app')
        .factory('linkedinFactory', linkedinFactory);

    // linkedinFactory.$inject = [];

    function linkedinFactory() {
        var service = {
            init: init,
            signIn: signIn,
            getUser: getUser,
            loginCheck: loginCheck
        };

        return service;

        function init() {
        }

        function signIn(callback) {
            return IN.User.authorize(callback);
        }

        function loginCheck() {
            return IN.User.isAuthorized();
        }

        function getUser() {
            return IN.API.Profile('me').fields('id', 'email-address','formatted-name','picture-url','public-profile-url');
        }
    }
})();
